import { NavLink } from 'react-router-dom';

// @components
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenuButton,
  useSidebar,
} from '@/components/ui/sidebar';
import { SidebarTrigger } from '@/components/ui/sidebar';
import { ScrollArea } from '@/components/ui/scroll-area'; // Import ScrollArea
import Logo from '../logo/logo';
import { RouteEnum } from '@/models/enums/routes.enum';
import { useUserContext } from '@/context/UserContextProvider';
import { _getUserType } from '@/services/session/SessionService';
import { If } from '../utility/if-else';
import { urls } from '@/utils/constants';

// @icons
import { House, Users, Receipt, HeartHandshake, FileText } from 'lucide-react';
import { CircleHelp } from 'lucide-react';
import { ArrowLeftFromLine } from 'lucide-react';
import { ArrowRightFromLine } from 'lucide-react';
import { Upload } from 'lucide-react';

const clientsSidebarItems = [
  {
    id: 1,
    title: 'Dashboard',
    icon: House,
    route: RouteEnum.clientDashboard,
  },
  {
    id: 2,
    title: 'Documents',
    icon: FileText,
    route: RouteEnum.documents,
  },
  {
    id: 3,
    title: 'Invoices',
    icon: Receipt,
    route: RouteEnum.invoice,
  },
  {
    id: 5,
    title: 'Support',
    icon: HeartHandshake,
    route: RouteEnum.support,
  },
];

const adminSidebarItems = [
  {
    id: 1,
    title: 'Dashboard',
    icon: House,
    route: RouteEnum.adminDashboard,
  },
  {
    id: 2,
    title: 'Clients',
    icon: Users,
    route: RouteEnum.clients,
  },
  {
    id: 3,
    title: 'Upload Documents',
    icon: Upload,
    route: RouteEnum.documentAdd,
  },
];

export function AppSidebar() {
  const { open, isMobile } = useSidebar();
  const userContext = useUserContext();
  const userType = _getUserType(userContext.user);
  const navItems = userType === 'client' ? clientsSidebarItems : adminSidebarItems;

  return (
    <Sidebar collapsible='icon'>
      <SidebarHeader className='flex items-center justify-center py-4'>
        <If condition={isMobile || open}>
          <Logo className='h-16 -translate-x-[10px]' />
        </If>
      </SidebarHeader>
      <div className='w-full h-[1px] bg-white'></div>
      {/* Wrap SidebarContent with ScrollArea */}
      <SidebarContent>
        <ScrollArea className='h-full'>
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                {navItems.map((item) => {
                  const Icon = item.icon;
                  return (
                    <SidebarMenuItem key={item.id}>
                      <SidebarMenuButton asChild className='py-4'>
                        <NavLink to={item.route} className={'flex items-center'}>
                          <Icon className='!size-4' />
                          <span className='ml-2 text-sm'>{item.title}</span>
                        </NavLink>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  );
                })}

                <If condition={userType === 'client'}>
                  <SidebarMenuItem>
                    <SidebarMenuButton asChild className='py-4'>
                      <a href={urls.faqsPageUrl} className={'flex items-center'} rel='noopener noreferrer' target='_blank'>
                        <CircleHelp className='!size-4' />
                        <span className='ml-2 text-sm'>FAQs</span>
                      </a>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                </If>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </ScrollArea>
      </SidebarContent>
      <SidebarFooter>
        <div className='flex justify-end'>
          <SidebarTrigger className='text-white'>{open ? <ArrowLeftFromLine /> : <ArrowRightFromLine />}</SidebarTrigger>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
