import { urls } from '@/utils/constants';
import logoUrl from '@/Assets/images/login/agent-logo.png';
import { cn } from '@/utils/cn';

const Logo = ({ className }: { className?: string }) => {
  return (
    <a href={urls.landingPageUrl} target='_blank' rel='noopener noreferrer' className='cursor-pointer'>
      <div className={cn('h-20', className)}>
        <img src={logoUrl} alt='Florida Register Agent Service with Unique Mail Forwarding Suite' className='max-h-full' />
      </div>
    </a>
  );
};

export default Logo;
