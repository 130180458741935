import React, { ReactNode } from "react";

type IfProps = {
  condition: boolean;
  children: ReactNode;
};

type ElseProps = {
  children: ReactNode;
};

type ConditionalRenderProps = {
  children: ReactNode;
};

const If: React.FC<IfProps> = ({ condition, children }) => {
  return condition ? <>{children}</> : null;
};

const Else: React.FC<ElseProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * A functional component that conditionally renders its children based on the presence
 * and evaluation of `If` and `Else` components.
 *
 * @component
 * @param {ConditionalRenderProps} props - The props for the ConditionalRender component.
 * @param {React.ReactNode} props.children - The children elements to be conditionally rendered.
 *
 * @returns {React.ReactElement | null} - Returns the `If` child if its condition is true,
 * otherwise returns the `Else` child if it exists.
 *
 * @example
 * ```tsx
 * <ConditionalRender>
 *   <If condition={true}>
 *     <div>This will be rendered if the condition is true.</div>
 *   </If>
 *   <Else>
 *     <div>This will be rendered if the condition is false.</div>
 *   </Else>
 * </ConditionalRender>
 * ```
 */
const ConditionalRender: React.FC<ConditionalRenderProps> = ({ children }) => {
  const validChildren = React.Children.toArray(children);
  const ifChild = validChildren.find(
    (child: unknown) => React.isValidElement(child) && child.type === If
  );
  const elseChild = validChildren.find(
    (child: unknown) => React.isValidElement(child) && child.type === Else
  );

  if (React.isValidElement(ifChild) && ifChild.props.condition) {
    return <>{ifChild}</>;
  }

  return <>{elseChild}</>;
};

export { ConditionalRender, If, Else };
