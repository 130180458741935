import { useUserContext } from '@/context/UserContextProvider';
import { RouteEnum } from '@/models/enums/routes.enum';
import { _getUserType } from '@/services/login/LoginService';
import { Navigate } from 'react-router-dom';

const RootComponent = () => {
  const user = useUserContext();
  let userType;
  if (user.user.clientId) {
    userType = 'client';
  } else if (user.user.agentId) {
    userType = 'agent';
  }

  const route: { [key: string]: string } = {
    agent: RouteEnum.adminDashboard,
    client: RouteEnum.clientDashboard,
  };
  const routeToRedirect = userType ? route[userType] : '/error';

  console.log('user', user);
  return <Navigate to={routeToRedirect} replace />;
};

export default RootComponent;
