import ReactDOM from 'react-dom/client';
import { Toaster } from 'sonner';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './index.scss';
import './index.css';
import App from './App';

import { ContextProvider } from './context/ContextProvider';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <ContextProvider>
      <App />
      <Toaster richColors position='top-right' />
    </ContextProvider>
  </QueryClientProvider>
);
reportWebVitals();
