import axios, { AxiosResponse } from "axios";
import { IClientAffiliateDto, IClientCouponDto, IClientDto, INewClientPayload, INewClientResponse } from "../../models/client/newRegisterAgent/IClientDto";
import { IResourcesStatusesDto } from "../../models/client/resourcesStatusesDto/IResourcesStatusesDto";
import { IClientInvoiceStatus } from "../../models/clientInvoice/IClientInvoiceStatus";
import { IStatusInvoices } from "../../models/client/invoices/IInvoices";
import { IClientInvoiceDto } from "../../models/clientInvoice/IClientInvoiceDto";
import { IClientServiceDto } from "../../models/clientService/IClientServiceDto";
import AffinipayPaymentToken from "../../models/affinipayPayment/affinipayPaymentToken/AffinipayPaymentToken";
import { IClientInvoicePymentSummary } from "../../models/clientInvoice/IClientInvoicePymentSummary";

const baseUrl = "client"

async function _getClientById(id: number): Promise<IClientDto> {
  let response = await axios.get(`${baseUrl}/${id}`)
  return response.data;
}

async function _postClientInformation(newClientPayload: INewClientPayload): Promise<INewClientResponse> {
  const response = await axios.post(baseUrl, newClientPayload);
  return response.data;
}

async function _getClientResourcesStatuses(): Promise<IResourcesStatusesDto> {
  const response = await axios.get(`${baseUrl}/resources/status`);
  return response.data;
}

async function _getClientInvoicesStatus(): Promise<IClientInvoiceStatus> {
  let response = await axios.get(`${baseUrl}/invoices/status`)
  return response.data;
}

async function _searchDocuments(): Promise<IClientDto[]> {
  let response = await axios.get(`${baseUrl}/documents`)
  return response.data;
}

async function _getStatusClientInvoices(): Promise<IStatusInvoices> {
  let response = await axios.get(`${baseUrl}/invoices/status`)
  return response.data;
}
async function _getInovices(): Promise<IClientInvoiceDto[]> {
  let response = await axios.get(`${baseUrl}/invoices`)
  return response.data;
}

async function _packagesSubscription(serviceId: number, servicePackageId: number, affinipayPaymentTokenId: string): Promise<IClientServiceDto> {
  const response = await axios.post(`${baseUrl}/services/packages/subscribe`, {
    "serviceSubscriptions": [
      {
        "serviceId": serviceId,
        "servicePackageId": servicePackageId
      }
    ],
    "affinipayPaymentDetails": {
      "token": affinipayPaymentTokenId
    }
  });
  return response.data;
}

async function _assignMailbox(clientId: Number, mailboxNumber: Number): Promise<IClientInvoiceDto[]> {
  let response = await axios.get(`${baseUrl}/assignMailbox/${clientId}/${mailboxNumber}`)
  return response.data;
}
async function _getMultipleInvoiceList(cliinvoiceIdsentId: string[]): Promise<IClientInvoicePymentSummary[]> {
  let response = await axios.post(`${baseUrl}/invoices/getMultipleInvoiceList`, cliinvoiceIdsentId)
  return response.data;
}
async function _validateCouponCode(code: string): Promise<AxiosResponse<IClientCouponDto>> {
  let response = await axios.get(`coupon/verify/${code}`)
  return response;
}
async function _validateAffiliateCode(code: string | undefined): Promise<IClientAffiliateDto> {
  let response = await axios.get(`affiliate/verify/${code}`)
  return response.data;
}

async function _payInvoices(invoiceIds: string[], token: string): Promise<boolean> {
  let response = await axios.post(`${baseUrl}/invoices/pay`,
    { invoiceIds: invoiceIds, token: token })
  return response.data;
}

async function _downlaodInvoiceList(invoiceIds: string[]): Promise<void> {
  const response = await axios.post(`${baseUrl}/invoices/download`, invoiceIds, {
    responseType: 'blob',
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${invoiceIds.join("_")}.pdf`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export { _getClientById, _postClientInformation, _getClientResourcesStatuses, _searchDocuments, _getClientInvoicesStatus, _getStatusClientInvoices, _getInovices, _packagesSubscription, _assignMailbox, _getMultipleInvoiceList, _downlaodInvoiceList, _payInvoices, _validateCouponCode, _validateAffiliateCode };