import { Link, Outlet } from 'react-router-dom';

// @components
import { Step, Stepper } from '@/components/ui/stepper';

// @icons
import { useSignupSteps } from '@/context/SignupStepsContext';

const Layout = () => {
  const { steps } = useSignupSteps();
  return (
    <div className='mt-2 overflow-hidden bg-white rounded-xl'>
      <div className='flex items-center justify-center p-4'>
        <h1 className='text-3xl font-semibold text-center uppercase font-montserrat text-primary-500'>
          <span className='font-bold'>Setup</span> Your Company
        </h1>
      </div>
      <Stepper>
        {steps.map((step, index) => (
          <Step key={index} title={step.title} active={step.active} isLast={index === steps.length - 1} isCompleted={step.isCompleted} />
        ))}
      </Stepper>
      <div>
        {/* region Outlet */}
        <Outlet />
        {/* endregion Outlet */}
      </div>
    </div>
  );
};

export default Layout;
