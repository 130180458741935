
// @components
import { ConditionalRender, Else, If } from '@/components/utility/if-else';

// @utils
import { cn } from '@/utils/cn';

// @icons
import { Check } from 'lucide-react';

interface StepperProps {
  children: React.ReactNode;
}

function Stepper({ children }: StepperProps) {
  return (
    <nav aria-label='Progress' className='mt-8'>
      <ol className='relative grid grid-cols-4 h-14 bg-slate-200 text-primary-500'>{children}</ol>
    </nav>
  );
}

interface StepProps {
  title: string;
  active?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
}

function Step({ title, active, isLast, isCompleted }: StepProps) {
  return (
    <li
      className={cn('relative z-10 flex items-center justify-center h-full', isCompleted || active ? 'bg-primary-500' : '')}
      role='listitem'
      aria-current={active ? 'step' : undefined}
    >
      {/* Step Circle */}
      <div
        className={cn(
          'absolute flex items-center justify-center rounded-full bg-slate-200 -z-10 -top-3/4 size-14',
          isCompleted ? 'bg-primary-500 text-white' : ''
        )}
        aria-label={isCompleted ? `Completed: ${title}` : title}
        role='button'
        tabIndex={0} // Makes it focusable
      >
        <ConditionalRender>
          <If condition={!!isCompleted}>
            <span aria-hidden='true'>
              <Check />
            </span>
          </If>
          <Else>
            <div className={cn('rounded-full size-8', active ? 'bg-primary-500' : 'bg-slate-400')}></div>
          </Else>
        </ConditionalRender>
      </div>

      {/* Step Line */}
      <If condition={!isLast}>
        <div
          className={cn(
            'absolute w-full h-1 translate-y-[12px] -top-1/2 -right-1/2 bg-slate-200 -z-20',
            isCompleted ? 'bg-primary-500' : ''
          )}
          aria-hidden='true' // Decorative element
        />
      </If>

      {/* Step Title */}
      <span className={cn('text-slate-400', isCompleted || active ? 'text-white' : '')} aria-hidden='true'>
        {title}
      </span>
    </li>
  );
}


export { Stepper, Step };