import { SignupContextProvider } from '@/context/SignupContextProvider';
import OutsideLayout from './outsideLayout';
import InsideLayout from './insideLayout';
import { ConditionalRender, Else, If } from '@/components/utility/if-else';
import SignupStepsContextProvider from '@/context/SignupStepsContext';

const SignUp = ({ outside }: { outside: boolean }) => {
  return (
    <SignupContextProvider>
      <SignupStepsContextProvider>
        <ConditionalRender>
          <If condition={outside}>
            <OutsideLayout />
          </If>
          <Else>
            <InsideLayout />
          </Else>
        </ConditionalRender>
      </SignupStepsContextProvider>
    </SignupContextProvider>
  );
};

export default SignUp;
