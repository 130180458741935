import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// @components
import SessionUser from '@/models/user/SessionUser';
import { _clearSession } from '@/services/session/SessionService';
import { useUserContext } from '@/context/UserContextProvider';
import Logo from '../logo/logo';
import { SidebarTrigger, useSidebar } from '@/components/ui/sidebar';
import { Button } from '../ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { _getUserType } from '@/services/session/SessionService';
import NotificationsDropdown from './notification';
import { RouteEnum } from '@/models/enums/routes.enum';
import { If } from '../utility/if-else';

import { Separator } from '../ui/separator';

// @icons
import { Bell, Settings, LogOut } from 'lucide-react';
import { Menu } from 'lucide-react';
import { User } from 'lucide-react';

const Header = () => {
  const userContext = useUserContext();
  const { open, isMobile } = useSidebar();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onLogOutClicked = () => {
    _clearSession();
    userContext.setUser(new SessionUser());
    queryClient.clear();
    navigate('/login');
  };
  const userType = _getUserType(userContext.user);

  const handleProfileClick = () => {
    navigate(`/${RouteEnum.clientProfile}/${userContext.user.clientId}`);
  };

  const handleAccountSettingsClick = () => {
    navigate(`/${RouteEnum.changePassword}`);
  };

  return (
    <header className='flex items-center justify-between w-full'>
      <div className='md:hidden'>
        <SidebarTrigger>
          <Menu />
        </SidebarTrigger>
      </div>
      <div>
        <If condition={!open}>
          <Logo className='h-12' />
        </If>
      </div>
      <div className='flex items-center gap-2'>
        <NotificationsDropdown />
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={'ghost'} className='px-2'>
                <div className='flex items-center gap-1'>
                  <div className='flex flex-col leading-none translate-y-1'>
                    <span className='font-semibold'>{userContext.user.firstName || userContext.user.email}</span>
                    <span className='text-sm'>{userType}</span>
                  </div>
                  <div className='flex items-center justify-center rounded-full bg-gray-500/20 size-10 aspect-square'>
                    <User size={120} />
                  </div>
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='py-2 bg-white'>
              <If condition={userType === 'client'}>
                <DropdownMenuItem className='p-0' onClick={handleProfileClick}>
                  <Button className='justify-start w-full h-auto gap-2 px-4 py-3 rounded-none text-primary-900' variant={'ghost'}>
                    <User />
                    <span>Profile</span>
                  </Button>
                </DropdownMenuItem>
              </If>
              <DropdownMenuItem className='p-0' onClick={handleAccountSettingsClick}>
                <Button className='justify-start w-full h-auto gap-2 px-4 py-3 rounded-none text-primary-900' variant={'ghost'}>
                  <Settings />
                  <span>Account Settings</span>
                </Button>
              </DropdownMenuItem>

              <Separator className='bg-gray-200 h-[1px]' />

              <DropdownMenuItem className='p-0'>
                <Button
                  onClick={onLogOutClicked}
                  className='justify-start w-full h-auto gap-2 px-4 py-3 rounded-none text-primary-900'
                  variant={'ghost'}
                >
                  <LogOut />
                  <span>Log Out</span>
                </Button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
};

export default Header;
