import React, { createContext, useState, ReactNode } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// components
import { SignUpFormData } from '@/pages/signUp/schema';
import { _postClientInformation } from '@/APIs/client/ClientApi';
import { INewClientPayload, IServiceSubscriptions } from '@/models/client/newRegisterAgent/IClientDto';
import { IAffinipayPaymentInformation } from '@/models/affinipayPayment/aaffinipayPayment/IAffinipayPayment';
import AffinipayPaymentToken from '@/models/affinipayPayment/affinipayPaymentToken/AffinipayPaymentToken';
import { toast } from 'sonner';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteEnum } from '@/models/enums/routes.enum';

interface SignupContextProps {
  formData: SignUpFormData;
  setFormData: React.Dispatch<React.SetStateAction<SignUpFormData>>;
  submit: () => void;
  hostedFields: IAffinipayPaymentInformation | undefined;
  setHostedFields: React.Dispatch<React.SetStateAction<IAffinipayPaymentInformation | undefined>>;
  couponCode: string;
  setCouponCode: React.Dispatch<React.SetStateAction<string>>;
  paymentErrors: PaymentErrors;
  setPaymentErrors: React.Dispatch<React.SetStateAction<PaymentErrors>>;
  affiliateCode: string;
  setAffiliateCode: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultFormData: SignUpFormData = {
  firstName: '',
  lastName: '',
  mobile: '',
  country: '',
  city: '',
  state: '',
  zipCode: '',
  address: '',
  email: '',
  password: '',
  confirmPassword: '',
  companyName: '',
  businessEntityType: '',
  attorneyEmail: '',
  notifyLawSuit: 'no',
  hireUs: 'no',
  companyAddress: undefined,
  addonServices: [],
  cardExpiry: '',
};

interface PaymentErrors {
  cardError: string;
  cvvError: string;
}

export const SignupContext = createContext<SignupContextProps | undefined>(undefined);

export const useSignupContext = () => {
  const context = React.useContext(SignupContext);
  if (!context) {
    throw new Error('useSignupContext must be used within a SignupContextProvider');
  }
  return context;
};

export const SignupContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<SignUpFormData>(defaultFormData);
  const [hostedFields, setHostedFields] = useState<IAffinipayPaymentInformation>();
  const [paymentErrors, setPaymentErrors] = useState<PaymentErrors>({ cardError: '', cvvError: '' });
  const [couponCode, setCouponCode] = useState<string>('');
  const [affiliateCode, setAffiliateCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({ formData, tokenId }: { formData: SignUpFormData; tokenId: string }) => {
      const mappedInformation = mapInformation(formData, tokenId);
      return _postClientInformation(mappedInformation);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clients'] });
      const basePath = pathname.includes('signup') ? RouteEnum.LoginNew : `/${RouteEnum.clients}`;
      navigate(basePath);
      setIsLoading(false);
      toast.success('Client registered successfully');
    },
    onError: (error: AxiosError) => {
      const { response } = error;
      const errorMessage = (response?.data as { details: string; })?.details;
      setIsLoading(false);
      toast.error(errorMessage);
    },
  });

  const submit = async () => {
    setIsLoading(true);
    const token = await getAffiniPayToken();
    await mutate({ formData, tokenId: token?.id ? token?.id : '' });
  };

  function mapInformation(formData: SignUpFormData, tokenId: string): INewClientPayload {
    const addressService = {
      active: formData.companyAddress?.active,
      servicePackageId: formData.companyAddress?.servicesPackages[0].id,
      serviceId: formData.companyAddress?.id,
      serviceName: formData.companyAddress?.name,
      servicePackageName: formData.companyAddress?.servicesPackages[0].name,
      serviceDescription: formData.companyAddress?.description,
      servicePackageDescription: formData.companyAddress?.servicesPackages[0].description,
      servicePackageAmount: formData.companyAddress?.servicesPackages[0].amount,
    };
    const optionalServices = formData.addonServices.map((service) => ({
      active: service.active,
      servicePackageId: service.servicesPackages[0].id,
      serviceId: service.id,
      serviceName: service.name,
      servicePackageName: service.servicesPackages[0].name,
      serviceDescription: service.description,
      servicePackageDescription: service.servicesPackages[0].description,
      servicePackageAmount: service.servicesPackages[0].amount,
    }));
    const mappedInformation: INewClientPayload = {
      clientInformation: {
        companyName: formData.companyName,
        businessType: parseInt(formData.businessEntityType),
        attorneyEmail: formData.attorneyEmail ? formData.attorneyEmail : '',
        notifyAttorney: formData.notifyLawSuit === 'yes',
        changeAgent: formData.hireUs === 'yes',
        useAgentAddress: formData.companyAddress ? true : false,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.mobile,
        countryId: parseInt(formData.country),
        address: formData.address,
        city: formData.city,
        stateProvince: formData.state,
        zipcode: formData.zipCode,
        agentId: null,
        affiliated_id: affiliateCode ? affiliateCode : undefined,
        leadGeneratedBy: '',
      },
      userInformation: {
        email: formData.email,
        password: formData.password,
      },
      serviceSubscriptions: [addressService as IServiceSubscriptions, ...optionalServices],
      affinipayPaymentDetails: {
        token: tokenId,
      },
      couponId: couponCode ? parseInt(couponCode) : 0,
    };
    return mappedInformation;
  }

  const getAffiniPayToken = async (): Promise<AffinipayPaymentToken | undefined> => {
    setPaymentErrors({ cardError: '', cvvError: '' });
    if (!hostedFields || typeof hostedFields.getState !== 'function' || !hostedFields.getState()) {
      console.error('Hosted fields not ready');
      return;
    }
    try {
      const token = await hostedFields.getPaymentToken({
        exp_year: formData.cardExpiry.split('/')[1],
        exp_month: formData.cardExpiry.split('/')[0],
        postal_code: formData.zipCode,
        name: formData.firstName + ' ' + formData.lastName,
        address1: formData.address,
      });
      return token;
    } catch (error: any) {
      setPaymentErrors({ error: error.message }.error);
    }
  };

  return (
    <SignupContext.Provider
      value={{
        formData,
        setFormData,
        hostedFields,
        setHostedFields,
        submit,
        couponCode,
        setCouponCode,
        paymentErrors,
        setPaymentErrors,
        affiliateCode,
        setAffiliateCode,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};
