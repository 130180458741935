import { createContext, useContext, useState } from 'react';
import ISessionUser from '../models/user/ISessionUser';

type UserContextType = {
  user: ISessionUser;
  setUser: (value: ISessionUser) => void;
};

export const UserContext = createContext<UserContextType>(undefined!);


export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};
