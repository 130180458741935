import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

// @components
import imageSrc from '@/Assets/images/login/agent-logo.png';
import { Step, Stepper } from '@/components/ui/stepper';

// @icons
import { MapPin } from 'lucide-react';
import { PhoneCall } from 'lucide-react';
import { Mail } from 'lucide-react';
import { RouteEnum } from '@/models/enums/routes.enum';
import { urls } from '@/utils/constants';
import { useSignupSteps } from '@/context/SignupStepsContext';

const Layout = () => {

  const { steps } = useSignupSteps();
  return (
    <div className='flex flex-col h-full min-h-screen'>
      <main className='p-2 flex-grow-1 bg-primary-500'>
        <div className='h-20'>
          <a href={urls.landingPageUrl} target='_blank' rel='noopener noreferrer' className='cursor-pointer'>
            <img src={imageSrc} alt='Florida Agents' className='max-h-full' />
          </a>
        </div>
        <div className='mt-2 overflow-hidden bg-white rounded-xl'>
          <div className='grid items-center justify-between grid-cols-1 p-8 lg:grid-cols-3'>
            <div className='hidden lg:block min-w-1' />
            <h1 className='text-3xl font-semibold text-center uppercase font-montserrat text-primary-500'>
              <span className='font-bold'>Setup</span> Your Company
            </h1>
            <div className='flex items-center justify-center gap-2 lg:justify-end '>
              <p>
                Have an account?{' '}
                <Link to={RouteEnum.LoginNew} className='font-semibold text-primary-500 hover:underline'>
                  LogIn
                </Link>
              </p>
            </div>
          </div>
          <Stepper>
            {steps.map((step, index) => (
              <Step
                key={index}
                title={step.title}
                active={step.active}
                isLast={index === steps.length - 1}
                isCompleted={step.isCompleted}
              />
            ))}
          </Stepper>
          <div>
            {/* region Outlet */}
            <Outlet />
            {/* endregion Outlet */}
          </div>
        </div>
      </main>
      <footer className='flex flex-col justify-center h-24 py-2 bg-white'>
        <div className='flex flex-grow-1'>
          <ul className='flex items-center justify-center w-full gap-4'>
            <li className='flex items-center justify-center gap-1'>
              <MapPin />
              Gainesville, Florida USA
            </li>
            <li className='flex items-center justify-center'>
              <a
                className='flex items-center justify-center gap-1 hover:underline'
                href='tel:+1(888) 991.5786 '
                target='_blank'
                rel='noopener noreferrer'
              >
                <PhoneCall />
                +1(888) 991.5786
              </a>
            </li>
            <li>
              <a
                className='flex items-center justify-center gap-1 hover:underline'
                href='mailto:help@floridaagents.net'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Mail />
                help@floridaagents.net
              </a>
            </li>
          </ul>
        </div>
        <p className='w-full text-center justify-self-end'>
          Copyright © 2025 <span className='font-semibold'>FLORIDAAGENTS</span>
        </p>
      </footer>
    </div>
  );
};

export default Layout;
