import { Suspense, ComponentType } from 'react';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

export function Loader() {
  return (
    <div
      className='fixed top-0 left-0 right-0 bg-primary-500/50 overflow-hidden z-[9999]'
      role='progressbar'
      aria-valuenow={0}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-label='Loading'
    >
      <div className='w-full h-2 rounded-full bg-primary-500 progress-bar-animation'></div>
    </div>
  );
}

const Loadable = (Component: ComponentType<any>) => (props: any) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
