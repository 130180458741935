import { lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/routeGuards/PrivateRoute';

// @components
import Loadable from './components/loadable/loadable';
import axiosInit from './utils/axios';

// @pages
import Layout from '@/components/layout/Layout';
import SignUpNew from '@/pages/signUp/signup_new';

const Login = Loadable(lazy(() => import('@/pages/login/Login_new')));
const ForgotPassword = Loadable(lazy(() => import('@/pages/forgotPassword/ForgotPassword')));
const Provider = Loadable(lazy(() => import('@/pages/providers/Provider')));
const Client = Loadable(lazy(() => import('@/pages/clients/Client')));
const ErrorPage = Loadable(lazy(() => import('@/pages/error/ErrorPage')));
const AgentInvoices = Loadable(lazy(() => import('@/pages/providers/invoices/AgentInvoices')));
const ClientHome = Loadable(lazy(() => import('@/pages/clients/home/ClientHome')));
const Support = Loadable(lazy(() => import('@/pages/clients/support/Support')));
const NotFound = Loadable(lazy(() => import('@/pages/notFound/NotFound')));
const ProviderRoutes = Loadable(lazy(() => import('@/components/routeGuards/ProviderRoutes')));
const ClientRoutes = Loadable(lazy(() => import('@/components/routeGuards/ClientRoutes')));
const RoleRoutes = Loadable(lazy(() => import('@/components/routeGuards/RoleRoutes')));
const SignUp = Loadable(lazy(() => import('@/pages/signUp/SignUp_old')));
const Addons = Loadable(lazy(() => import('@/pages/clients/addons/Addons')));
const Documents = Loadable(lazy(() => import('@/pages/providers/documents/Documents')));
const Clients = Loadable(lazy(() => import('@/pages/providers/home/provider-clients_new')));
const ClientInvoiceDetail = Loadable(lazy(() => import('@/pages/clients/invoices/ClientInvoiceDetail')));
const PasswordReset = Loadable(lazy(() => import('@/pages/login/PasswordReset')));
const PersonalInformation = Loadable(lazy(() => import('@/pages/signUp/personal-information/PersonalInformation')));
const CompanyInformation = Loadable(lazy(() => import('@/pages/signUp/company-information/CompanyInformation')));
const ChooseAddress = Loadable(lazy(() => import('@/pages/signUp/choose-address/ChooseAddress')));
const OrderSummary = Loadable(lazy(() => import('@/pages/signUp/order-summary/OrderSummary')));
const ClientDocumentsNew = Loadable(lazy(() => import('@/pages/clients/documents/client-document_new')));
const AdminDashboard = Loadable(lazy(() => import('@/pages/admin-dashboard/admin-dashboard')));
const ClientProfile = Loadable(lazy(() => import('./pages/client-profile/client-profile')));
const ClientInvoices = Loadable(lazy(() => import('./pages/clients/invoices/client-invoice_new')));
const DocumentAdd = Loadable(lazy(() => import('@/pages/providers/documents/addDocument')));
const ChangePassword = Loadable(lazy(() => import('./pages/account-settings/change-password')));

// @types
import { RouteEnum } from './models/enums/routes.enum';
import RootComponent from './components/root/root';

function App() {
  axiosInit();

  return (
    <BrowserRouter>
      <Routes>
        <Route path={RouteEnum.Signup_new} element={<SignUpNew outside={true} />}>
          <Route index element={<Navigate to={RouteEnum.PersonalInformation} replace />} />
          <Route path={RouteEnum.PersonalInformation} element={<PersonalInformation />} />
          <Route path={RouteEnum.CompanyInformation} element={<CompanyInformation />} />
          <Route path={RouteEnum.ChooseAddress} element={<ChooseAddress />} />
          <Route path={RouteEnum.OrderSummary} element={<OrderSummary />} />
        </Route>
        <Route path='signUp' element={<SignUp />} />
        <Route path={RouteEnum.LoginNew} element={<Login />} />
        <Route path={RouteEnum.ForgotPassword} element={<ForgotPassword />} />
        <Route path='reset/:token' element={<PasswordReset />} />
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<RootComponent />} />
          <Route path={RouteEnum.changePassword} element={<ChangePassword />} />
          {/* Admin Routes */}
          <Route
            path='provider'
            element={
              <ProviderRoutes>
                <Provider />
              </ProviderRoutes>
            }
          >
            <Route index element={<Navigate to={RouteEnum.adminDashboard.split('/')[1]} />} />
            <Route path={RouteEnum.adminDashboard.split('/')[1]} element={<AdminDashboard />} />
            <Route path={RouteEnum.clients.split('/')[1]} element={<Clients />} />
            <Route path={RouteEnum.documentAdd.replace('provider/','')} element={<DocumentAdd />} />
            <Route path='clients/:id' element={<ClientProfile />} />
            <Route path='invoices/:id' element={<ClientInvoiceDetail />} />
            <Route
              path='invoices'
              element={
                <RoleRoutes rolesAllowed={['not-fully-implemented-yet']}>
                  <AgentInvoices />
                </RoleRoutes>
              }
            />
            <Route
              path='Documents'
              element={
                <RoleRoutes rolesAllowed={['not-fully-implemented-yet']}>
                  <Documents />
                </RoleRoutes>
              }
            />

            <Route path={RouteEnum.addClient.split('/')[1]} element={<SignUpNew outside={false} />}>
              <Route index element={<Navigate to={RouteEnum.PersonalInformation} replace />} />
              <Route path={RouteEnum.PersonalInformation} element={<PersonalInformation />} />
              <Route path={RouteEnum.CompanyInformation} element={<CompanyInformation />} />
              <Route path={RouteEnum.ChooseAddress} element={<ChooseAddress />} />
              <Route path={RouteEnum.OrderSummary} element={<OrderSummary />} />
            </Route>
          </Route>

          {/* // Client Routes  */}
          <Route
            path='client'
            element={
              <ClientRoutes>
                <Client />
              </ClientRoutes>
            }
          >
            <Route index element={<Navigate to={RouteEnum.clientDashboard.split('/')[1]} />} />
            <Route path={RouteEnum.clientDashboard.split('/')[1]} element={<ClientHome />} />
            <Route path={`${RouteEnum.clientProfile.split('/')[1]}/:id`} element={<ClientProfile />} />
            {/* <Route path="invoices" element={
              <RoleRoutes rolesAllowed={["not-fully-implemented-yet"]}>
                <ClientInvoices />
              </RoleRoutes>
            } /> */}
            <Route path={RouteEnum.documents.split('/')[1]} element={<ClientDocumentsNew />} />
            <Route path='addons' element={<Addons />} />
            <Route path={RouteEnum.invoice.split('/')[1]} element={<ClientInvoices />} />
            <Route path='invoices/:id' element={<ClientInvoiceDetail />} />
            <Route path='support' element={<Support />} />
          </Route>

          {/* Utility Pages */}
          <Route path='*' element={<NotFound />} />
          <Route path='error' element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
