import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../../utils/cn';

const colorClasses = {
  default: {
    primary: 'bg-primary-500 text-primary-foreground hover:bg-primary-500/90',
    secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
    error: 'bg-error text-error-foreground hover:bg-error/90',
    info: 'bg-info text-info-foreground hover:bg-info/90',
    warning: 'bg-warning text-warning-foreground hover:bg-warning/90',
    success: 'bg-success text-success-foreground hover:bg-success/90',
  },
  outline: {
    primary: 'border !border-primary-500 text-primary-500 hover:!bg-primary-500/10',
    secondary: 'border border-secondary text-secondary hover:bg-secondary/10',
    error: 'border border-error text-error hover:bg-error/10',
    success: 'border border-success text-success hover:bg-success/10',
    info: 'border border-info text-info hover:bg-info/10',
    warning: 'border border-warning text-warning hover:bg-warning/10',
  },
  ghost: {
    primary: 'text-primary-500 hover:bg-primary-500/10',
    secondary: 'text-secondary hover:bg-secondary/10',
    error: 'text-error hover:bg-error/10',
    success: 'text-success hover:bg-success/10',
    info: 'text-info hover:bg-info/10',
    warning: 'text-warning hover:bg-warning/10',
  },
  link: {
    primary: 'text-primary hover:text-primary/90',
    secondary: 'text-secondary hover:text-secondary/90',
    error: 'text-error hover:text-error/90',
    success: 'text-success hover:text-success/90',
    info: 'text-info hover:text-info/90',
    warning: 'text-warning hover:text-warning/90',
  },
};

const buttonVariants = cva(
  'inline-flex items-center !font-montserrat justify-center gap-2 whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  {
    variants: {
      variant: {
        default: 'shadow rounded-lg',
        outline: 'bg-transparent shadow-sm',
        ghost: '',
        link: 'underline-offset-4 hover:underline',
      },
      size: {
        sm: 'h-8 rounded-lg px-2 text-xs',
        md: 'h-12 px-4 py-2',
        lg: 'h-14 rounded-lg px-8',
        icon: 'size-9 aspect-square rounded-full bg-gray-400/20 hover:bg-gray-500',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', color = 'primary', size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    const colorClass = variant ? colorClasses[variant]?.[color] : '';

    return <Comp className={cn(buttonVariants({ variant, size }), colorClass, className)} ref={ref} {...props} />;
  }
);
Button.displayName = 'Button';

export { Button };
