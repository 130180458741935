import axios from 'axios';
import { toast } from 'sonner';

export const axiosInit = () => {
  axios.defaults.baseURL = process.env.REACT_APP_REGISTEREDAGENTSSERVICESAPI_URL!;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
};

// configure interceptors: pick auth header from session storage - add it to all requests
axios.interceptors.request.use(function (config) {
  let authorizationHeader = getAuthorizationHeader();
  if (config.headers !== undefined) {
    config.headers.Authorization = authorizationHeader;
  }
  return config;
});

function getAuthorizationHeader() {
  let auththorizationHeader = '';
  let session = sessionStorage.getItem(process.env.REACT_APP_SESSION_KEY!);
  if (session !== null) {
    auththorizationHeader = 'Bearer ' + session;
  }
  return auththorizationHeader;
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log('error==>',error)
    if (error.code === 'ERR_NETWORK') {
      sessionStorage.removeItem(process.env.REACT_APP_SESSION_KEY!);
      toast.error('Network error, Try reloading the page');
      return;
    }
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem(process.env.REACT_APP_SESSION_KEY!);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInit;
