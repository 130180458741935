import ISessionUser from '../../models/user/ISessionUser';
import SessionUser from '../../models/user/SessionUser';
import ISessionService from './ISessionService';
import jwt_decode from 'jwt-decode';

class SessionService implements ISessionService {
  getSession(): string | null {
    return _getSession();
  }
}

function _getSession(): string | null {
  return sessionStorage.getItem(process.env.REACT_APP_SESSION_KEY!);
}

function _clearSession(): void {
  sessionStorage.removeItem(process.env.REACT_APP_SESSION_KEY!);
}

const _getSessionUser = (session: string): SessionUser => {
  return jwt_decode<ISessionUser>(session);
};

const _getUserType = (user: ISessionUser): string => {
  let userType: string = 'undefined';
  if (user && user.clientId && user.clientId.length > 0) {
    userType = 'client';
  } else if (user && user.agentId && user.agentId.length > 0) {
    userType = 'agent';
  }
  return userType;
};

export { SessionService, _getSession, _getUserType, _clearSession, _getSessionUser };
