import { Outlet } from 'react-router-dom';

// @components
import { SidebarProvider } from '@/components/ui/sidebar';
import { AppSidebar } from './app-sidebar';
import Header from './header';
import { cn } from '@/utils/cn';

const Layout = () => {
  return (
    <SidebarProvider>
      <AppSidebar />

      <main className='flex-grow w-full h-screen overflow-hidden'>
        <div className='flex flex-col h-full'>
          <div className={cn('flex items-center justify-between w-full px-4 py-3 bg-white md:justify-end shadow-md relative z-10')}>
            <Header />
          </div>
          <div className='flex-grow px-4 py-4 overflow-auto md:px-6 lg:px-8'>
            <Outlet />
          </div>
        </div>
      </main>

      {/* </div> */}
    </SidebarProvider>
  );
};

export default Layout;
