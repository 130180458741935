export enum RouteEnum {
  'LoginNew' = '/login',
  'ForgotPassword' = '/forgot-password',
  'Signup_new' = '/signup/',
  addClient = 'provider/add-client',
  PersonalInformation = 'personal-information',
  CompanyInformation = 'company-details',
  ChooseAddress = 'choose-address',
  OrderSummary = 'order-summary',
  invoice = 'client/invoices',
  support = 'client/support',
  documents = 'client/documents',
  clientDashboard = 'client/dashboard',
  clientProfile = 'client/profile',
  clients = 'provider/clients',
  adminDashboard = 'provider/dashboard',
  documentAdd = 'provider/documents/add',
  changePassword = 'account-settings/change-password',
}
