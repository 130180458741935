import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// @components
import { RouteEnum } from '@/models/enums/routes.enum';

interface Step {
  title: string;
  active: boolean;
  isCompleted: boolean;
  nextRoute: string;
  prevRoute: string;
}

const stepsArray = [
  {
    title: 'Personal Information',
    active: true,
    isCompleted: false,
    nextRoute: `:basePath/${RouteEnum.CompanyInformation}`,
    prevRoute: '',
  },
  {
    title: 'Company Details',
    active: false,
    isCompleted: false,
    nextRoute: `:basePath/${RouteEnum.ChooseAddress}`,
    prevRoute: `:basePath/${RouteEnum.PersonalInformation}`,
  },
  {
    title: 'Choose Address',
    active: false,
    isCompleted: false,
    nextRoute: `:basePath/${RouteEnum.OrderSummary}`,
    prevRoute: `:basePath/${RouteEnum.CompanyInformation}`,
  },
  {
    title: 'Order Summary',
    active: false,
    isCompleted: false,
    nextRoute: '',
    prevRoute: `:basePath/${RouteEnum.ChooseAddress}`,
  },
];

interface SignupContextProps {
  steps: Step[];
  setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
}

export const SignupContext = createContext<SignupContextProps | undefined>(undefined);

export const useSignupSteps = () => {
  const context = React.useContext(SignupContext);
  if (!context) {
    throw new Error('useSignupContext must be used within a SignupContextProvider');
  }
  return context;
};

const SignupStepsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [steps, setSteps] = useState<Step[]>(stepsArray);

  const { pathname } = useLocation();

  useEffect(() => {
    const basePath = pathname.includes('signup') ?'/'+ RouteEnum.Signup_new.split('/')[1] : '/' + RouteEnum.addClient;

    const currentPath = pathname.split('/')[2]?.replace(/-/g, ' ');

    setSteps((prevSteps) =>
      prevSteps.map((step, index) => {
        const isActive = pathname.includes(step.title.toLowerCase().replace(/\s+/g, '-'));
        const isCompleted = index < prevSteps.findIndex((s) => s.title.toLowerCase().includes(currentPath));

        return {
          ...step,
          nextRoute: step.nextRoute.replace(':basePath', basePath),
          prevRoute: step.prevRoute.replace(':basePath', basePath),
          active: isActive,
          isCompleted,
        };
      })
    );
  }, [pathname]);

  return <SignupContext.Provider value={{ steps, setSteps }}>{children}</SignupContext.Provider>;
};

export default SignupStepsContextProvider;
