import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Bell } from 'lucide-react';
import { Button } from '../ui/button';


const NotificationsDropdown = () => {
  const notifications = [
    { id: 1, text: 'You have a new message', read: false },
    { id: 2, text: 'Your order has been shipped', read: true },
    { id: 3, text: 'Reminder: Meeting at 3 PM', read: false },
  ];

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button variant='ghost' size='icon' className='relative'>
          <Bell className='w-6 h-6 text-gray-700' />
          {notifications.some((n) => !n.read) && <span className='absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full' />}
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content align='end' className='min-w-[300px] bg-white border border-gray-200 rounded-lg shadow-lg mt-2 p-2 z-[99999]'>
          <DropdownMenu.Label className='px-2 py-1 text-sm font-semibold text-gray-700'>Notifications</DropdownMenu.Label>
          <DropdownMenu.Separator className='h-px my-1 bg-gray-200' />

          {notifications.map((notification) => (
            <DropdownMenu.Item
              key={notification.id}
              className={`px-2 py-1.5 text-sm rounded-md ${!notification.read ? 'bg-gray-50' : ''} hover:bg-gray-100 cursor-pointer`}
            >
              {notification.text}
            </DropdownMenu.Item>
          ))}

          <DropdownMenu.Separator className='h-px my-1 bg-gray-200' />
          <DropdownMenu.Item className='px-2 py-1.5 text-sm text-blue-600 hover:bg-gray-100 cursor-pointer'>
            Mark all as read
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default NotificationsDropdown;
